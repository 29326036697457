var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('van-swipe',{staticClass:"swiper",on:{"change":_vm.onChange},scopedSlots:_vm._u([{key:"indicator",fn:function(){return [_c('div',{staticClass:"custom-indicator"},[_vm._v(_vm._s(_vm.current + 1)+"/4")])]},proxy:true}])},_vm._l((4),function(item,index){return _c('van-swipe-item',{key:index},[_c('img',{attrs:{"src":"/img/rouImg.png","alt":""}})])}),1),_vm._m(0),_c('div',{staticClass:"title-box"},[_vm._m(1),_c('div',{staticClass:"flex ai-c jc-sb",staticStyle:{"margin-top":".55rem","margin-bottom":".3rem"}},[_c('div',{staticClass:"sold"},[_vm._v("已售1145件")]),_c('div',{staticClass:"collection flex ai-c isCollection"},[_c('van-icon',{attrs:{"name":"like"}}),_vm._v(" 收藏 ")],1)])]),_c('div',{staticClass:"position flex ai-c jc-sb"},[_c('div',{staticClass:"position-txt lines"},[_vm._v(" 牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅 ")]),_c('div',{staticClass:"more flex ai-c"},[_c('img',{staticClass:"icon-position",attrs:{"src":"/img/icon-position.png","alt":""}}),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"goods-list"},[_c('div',{staticClass:"list-title"},[_vm._v("可选套餐")]),_vm._l((3),function(item,index){return _c('div',{key:index,staticClass:"item flex ai-c jc-sb"},[_vm._m(2,true),_c('div',{staticClass:"btn",on:{"click":_vm.btnClick}},[_vm._v("立即抢购")])])})],2),_vm._m(3),_vm._m(4),_c('div',{staticClass:"btn-box flex"},[_c('div',{staticClass:"btn1 flex fd-c ai-c jc-c",on:{"click":function($event){return _vm.$router.push('/home')}}},[_c('img',{attrs:{"src":"/img/icon-home1.png","alt":""}}),_vm._v(" 首页 ")]),_vm._m(5),_c('div',{staticClass:"btn2",on:{"click":_vm.btnClick}},[_vm._v("立即购买")])]),_c('v-sku',{ref:"sku"})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"activity-time flex ai-c jc-sb"},[_c('div',{staticClass:"activity-l flex ai-c"},[_c('div',{staticClass:"lb"},[_vm._v("限时")]),_c('div',{},[_c('div',{staticClass:"money"},[_vm._v("98.00")]),_c('div',{staticClass:"o-money"},[_vm._v("¥266.00")])])]),_c('div',{staticClass:"activity-r flex fd-c ai-fd"},[_c('div',{staticClass:"t1"},[_vm._v("距活动结束还有")]),_c('div',{staticClass:"flex ai-c"},[_c('div',{staticClass:"txt"},[_vm._v("27天 ")]),_c('div',{staticClass:"nb"},[_vm._v("12")]),_c('div',{staticClass:"txt"},[_vm._v(":")]),_c('div',{staticClass:"nb"},[_vm._v("12")]),_c('div',{staticClass:"txt"},[_vm._v(":")]),_c('div',{staticClass:"nb"},[_vm._v("12")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex jc-sb"},[_c('div',{staticClass:"title"},[_vm._v(" 火遍厦门的【牧牛人潮汕牛肉火锅】来啦！秘制汤底、现切牛肉，地道潮汕风味！吊龙、匙仁、手打 ")]),_c('div',{staticClass:"share flex ai-c jc-c"},[_c('img',{attrs:{"src":"/img/share.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex ai-c"},[_c('img',{staticClass:"goods-img",attrs:{"src":"/img/rouImg.png","alt":""}}),_c('div',{staticClass:"txt-box"},[_c('div',{staticClass:"t1 lines"},[_vm._v("3-4人餐")]),_c('div',{staticClass:"t2"},[_vm._v("¥98")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nav flex ai-c"},[_c('div',{staticClass:"nav-item active"},[_vm._v("商品详情")]),_c('div',{staticClass:"nav-item"},[_vm._v("购买须知")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"nr"},[_c('div',{staticClass:"nr-title"},[_vm._v("商品详情")]),_c('div',{staticClass:"fwb"},[_vm._v("商品详情这里显示")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"btn1 flex fd-c ai-c jc-c"},[_c('img',{attrs:{"src":"/img/icon-kefu.png","alt":""}}),_vm._v(" 客服 ")])
}]

export { render, staticRenderFns }