<template>
  <div class="wrapper">
    <van-swipe class="swiper" @change="onChange">
      <van-swipe-item v-for="(item, index) in 4" :key="index">
        <img src="/img/rouImg.png" alt="">
      </van-swipe-item>
      <template #indicator>
        <div class="custom-indicator">{{ current + 1 }}/4</div>
      </template>
    </van-swipe>
    <div class="activity-time flex ai-c jc-sb">
      <div class="activity-l flex ai-c">
        <div class="lb">限时</div>
        <div class="">
          <div class="money">98.00</div>
          <div class="o-money">¥266.00</div>
        </div>
      </div>
      <div class="activity-r flex fd-c ai-fd">
        <div class="t1">距活动结束还有</div>
        <div class="flex ai-c">
          <div class="txt">27天 </div>
          <div class="nb">12</div>
          <div class="txt">:</div>
          <div class="nb">12</div>
          <div class="txt">:</div>
          <div class="nb">12</div>
        </div>
      </div>
    </div>
    <div class="title-box">
        <div class="flex jc-sb">
          <div class="title">
            火遍厦门的【牧牛人潮汕牛肉火锅】来啦！秘制汤底、现切牛肉，地道潮汕风味！吊龙、匙仁、手打
          </div>
          <div class="share flex ai-c jc-c">
            <img src="/img/share.png" alt="">
          </div>
        </div>
        <div class="flex ai-c jc-sb" style="margin-top:.55rem;margin-bottom:.3rem">
          <div class="sold">已售1145件</div>
          <div class="collection flex ai-c isCollection">
            <van-icon name="like"/>
            收藏
          </div>
        </div>
      </div>
      <div class="position flex ai-c jc-sb">
        <div class="position-txt lines">
          牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅牧牛人潮汕牛肉火锅
        </div>
        <div class="more flex ai-c">
          <img class="icon-position" src="/img/icon-position.png" alt="">
          <van-icon name="arrow" />
        </div>
      </div>
      <div class="goods-list">
        <div class="list-title">可选套餐</div>
        <div class="item flex ai-c jc-sb" v-for="(item, index) in 3" :key="index">
          <div class="flex ai-c">
            <img class="goods-img" src="/img/rouImg.png" alt="">
            <div class="txt-box">
              <div class="t1 lines">3-4人餐</div>
              <div class="t2">¥98</div>
            </div>
          </div>
          <div class="btn" @click="btnClick">立即抢购</div>
        </div>
      </div>
      <div class="nav flex ai-c">
        <div class="nav-item active">商品详情</div>
        <div class="nav-item">购买须知</div>
      </div>
      <div class="nr">
        <div class="nr-title">商品详情</div>
        <div class="fwb">商品详情这里显示</div>
      </div>
      <div class="btn-box flex">
        <div class="btn1 flex fd-c ai-c jc-c" @click="$router.push('/home')">
          <img src="/img/icon-home1.png" alt="">
          首页
        </div>
        <div class="btn1 flex fd-c ai-c jc-c">
          <img src="/img/icon-kefu.png" alt="">
          客服
        </div>
        <div class="btn2" @click="btnClick">立即购买</div>
      </div>
      <v-sku ref="sku"/>
  </div>
</template>

<script>
import sku from '@/components/sku'
export default {
  components: {
    'v-sku': sku
  },
  props: {},
  data() {
    return {
      current: 0,
    };
  },
  watch: {},
  computed: {},
  methods: {
    onChange(index) {
      this.current = index;
    },
    btnClick(type){
      this.$refs.sku.show = true
    }
  },
  created() {},
  mounted() {},
};
</script>
<style scoped>
.wrapper .swiper{
  position: relative;
  width: 100%;
  height: 8.78rem;
}
.wrapper .swiper img{
  display: block;
  width: 100%;
  height: 8.78rem;
}
.custom-indicator {
  position: absolute;
  right: .3rem;
  bottom: .3rem;
  padding: .02rem .05rem;
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #3C3C3C;
  background: #FFFFFF;
  border-radius: .02rem;
}
.activity-time{
  width: 100%;
  height: 1.18rem;
  background-image: url(/img/activity-time.png);
  background-size: 100% 100%;
}
.activity-l .lb{
  width: .76rem;
  height: .44rem;
  line-height: .44rem;
  text-align: center;
  border: .02rem solid #F34B25;
  border-radius: .04rem;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: 500;
  color: #F35E3A;
  margin-left: .22rem;
  margin-right: .33rem;
}
.activity-l .money{
  position: relative;
  font-size: .56rem;
  font-family: PingFang;
  font-weight: bold;
  color: #F35E3A;
  line-height: .58rem;
  margin-top: -.1rem;
}
.activity-l .money::before{
  content: '¥';
  position: absolute;
  bottom: -.08rem;
  left: -.3rem;
  font-size: .32rem;
  font-weight: 500;
  color: #F35E3A;
}
.activity-l .o-money{
  font-size: .25rem;
  font-family: PingFang;
  font-weight: 500;
  color: #676767;
  line-height: .27rem;
  text-decoration: line-through;
  margin-top: .1rem;
}
.activity-r{
  margin-right: .3rem;
}
.activity-r .t1{
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
}
.activity-r .txt{
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
}
.activity-r .nb{
  width: .40rem;
  height: .46rem;
  text-align: center;
  line-height: .46rem;
  background: #FFFFFF;
  border-radius: .10rem;
  margin: 0 .05rem;
  font-size: .26rem;
  font-family: PingFang;
  font-weight: 500;
  color: #F9591B;
}
.title-box {
  background-color: #fff;
  overflow: hidden;
}
.title-box .title{
  width: 5.5rem;
  font-size: .32rem;
  font-family: PingFang;
  font-weight: bold;
  color: #282828;
  margin-left: .28rem;
  margin-top: .3rem;
}
.title-box .share{
  width: 1rem;
  height: .9rem;
  border-left: 1px solid #e7e7e7;
  margin-top: .3rem;
}
.title-box .share img{
  width: .43rem;
}
.title-box .sold{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #8B8B8B;
  margin-left: .3rem;
}
.title-box .collection{
  font-size: .24rem;
  font-family: PingFang;
  font-weight: 500;
  color: #8B8B8B;
  margin-right: .3rem;
}
.title-box>>>.van-icon-like{
  margin-right: .18rem;
  font-size: .28rem;
  color: rgba(215, 205, 212, 1);
}
.isCollection>>>.van-icon-like{
  color: rgba(249, 111, 48, 1)
}
.position{
  padding: .32rem 0;
  background-color: #fff;
  margin-top: .2rem;
}
.position .position-txt{
  width: 6rem;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
  margin-left: .28rem;
}
.position .more{
  margin-right: .2rem;
}
.position .icon-position{
  width: .35rem;
  height: .30rem;
}
.goods-list{
  padding: .4rem 0 .2rem;
  background-color: #fff;
  margin-top: .2rem;
}
.goods-list .list-title{
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
  margin-left: .3rem;
}
.goods-list .item{
  padding: .2rem 0;
}
.goods-list .goods-img{
  width: 1.14rem;
  height: 1.14rem;
  margin-left: .3rem;
  margin-right: .3rem;
}
.goods-list .t1{
  width: 3rem;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
}
.goods-list .t2{
  font-size: .34rem;
  font-family: PingFang;
  font-weight: bold;
  color: #F35E3A;
  margin-top: .1rem;
}
.goods-list .btn{
  width: 1.76rem;
  height: .60rem;
  line-height: .6rem;
  text-align: center;
  background: #F96F30;
  border-radius: .30rem;
  font-size: .28rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  margin-right: .3rem;
}
.nav {
  width: 100%;
  height: 1.30rem;
  background: #F4F4F4;
}
.nav .nav-item{
  position: relative;
  flex:1;
  text-align: center;
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
}
.nav .active{
  font-size: .36rem;
  font-weight: bold;
  color: #F96F30;
}
.nav .active::before{
  content: '';
  position: absolute;
  left: calc(50% - 0.7rem);
  bottom: -0.15rem;
  width: 1.40rem;
  height: .06rem;
  background: #F96F30;
  border-radius: .03rem;
}
.nr .nr-title{
  font-size: .30rem;
  font-family: PingFang;
  font-weight: bold;
  color: #353535;
  margin-top: .7rem;
  margin-left: .3rem;
}
.fwb{

}
.btn-box{
  position: fixed;
  bottom: 0;
  left: 0;
  width: 7.50rem;
  height: 1rem;
  background: #FFFFFF;
  border-top: 1px solid rgba(178, 178, 178, 0.6);
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}
.btn-box .btn1{
  width: 1.8rem;
  height: 100%;
  font-size: .22rem;
  font-family: PingFang;
  font-weight: 500;
  color: #61626A;
}
.btn-box .btn1 img{
  height: .36rem;
  margin-top: .1rem;
}
.btn-box .btn1:nth-child(1){
  border-right: 1px solid rgba(178, 178, 178, 0.6);
}
.btn-box .btn2{
  flex: 1;
  height: 100%;
  background: #F35E3A;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  font-size: .36rem;
  font-family: PingFang;
  font-weight: 500;
  color: #FFFFFF;
  text-align: center;
  line-height: 1rem;
}
</style>